.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* new */
/* General Styles */
body {
  font-family: 'Open Sans', sans-serif;
  background-color: #f4f4f4;
  color: #333;
  margin: 0;
  padding: 0;
}

/* App Specific Styles */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Main Container Styles */
.container {
  padding: 20px;
}

/* Section Headings */
h1, h2 {
  color: #024959;
  margin-bottom: 20px;
}

/* Paragraphs */
p {
  line-height: 1.6;
  margin-bottom: 15px;
}

/* List Styles */
ul {
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 20px;
}

/* Card Styles */
.card {
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Property Card Specific Styles */
.property-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.property-card img {
  height: 250px; /* Adjust height for consistency */
  object-fit: cover;
}

.property-card .card-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Navbar Styles */
.navbar {
  background-color: #024959;
  z-index: 1030;
  position: fixed;
  width: 100%;
}

/* Adjust Navbar Links */
.navbar-brand, .nav-link {
  color: #ffffff !important;
}

/* Responsive Design */
@media (max-width: 768px) {
  .card {
    margin-bottom: 15px;
  }
}

/* Testimonials Section */
.testimonials {
  margin-top: 30px;
  text-align: center;
}

.testimonials h3 {
  margin-bottom: 20px;
  color: #024959;
}

/* Contact Information */
.contact-info {
  background-color: #3b3b3b;
  color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-info h3 {
  margin-bottom: 20px;
}

.contact-info p, .contact-info a {
  margin-bottom: 10px;
}

.contact-info a {
  color: black;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

/* Blockquote Styles */
blockquote {
  margin: 0;
  padding: 10px 20px;
  background-color: #e9ecef;
  border-left: 5px solid #024959;
}

.blockquote-footer {
  font-size: 0.85em;
  color: #555;
}

/* Footer */
footer {
  background-color: #ffc107;
  color: #212529;
  padding: 20px 0;
  text-align: center;
}

footer a {
  color: #212529;
}

footer a:hover {
  text-decoration: none;
  color: #fff;
}

/* About Us Page Styles */
.about-section {
  padding: 40px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Modal Styles */
.modal-content {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.modal-header {
  background-color: #3b3b3b;
  color: white;
  border-bottom: none;
}

.modal-title {
  font-weight: 600;
}

.close {
  background: none;
  border: none;
  color: #fff;
}

.modal-body {
  padding: 20px;
}

.form-control {
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
}

.btn-warning {
  background-color: #ffc107;
  border-color: #ffc107;
  color: #212529;
}

.btn-warning:hover {
  background-color: #e0a800;
  border-color: #daa520;
}

/* Custom Modal Styles */
.modal-header {
  background-color: #3b3b3b; /* Primary color */
  color: white;
  border-bottom: none; /* Remove the default border */
}

.modal-title {
  font-weight: 600;
}

.modal-header .close {
  color: white;
  text-shadow: none; /* Remove Bootstrap's default text-shadow */
  opacity: 1;
}

.modal-body {
  padding: 20px;
}

.form-group {
  margin-bottom: 1rem;
}

.form-control {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  height: auto;
  padding: 0.375rem 0.75rem;
}

.form-control:focus {
  border-color: #80bdff;
  box-shadow: inset 0 1px 1px rgba(0, 123, 255, 0.075), 0 0 8px rgba(128, 189, 255, 0.6);
}

.btn-warning {
  background-color: #ffc107; /* Warning color for the button */
  border-color: #ffc107;
  color: #212529;
}

.btn-warning:hover {
  background-color: #e0a800; /* Darker on hover */
  border-color: #d39e00;
}
/* footer */
html, body {
  height: 100%; /* ensure full height */
  margin: 0; /* reset default margin */
}
body {
  display: flex;
  flex-direction: column; /* stack content vertically */
  font-family: 'Open Sans', sans-serif;
  background-color: #f4f4f4;
  color: #333;
}
/* Add this CSS to adjust the main content area */

.content-wrap {
  flex: 1; /* Expand to fill available space */
  padding: 20px; /* Some padding around content */
  margin-top: 70px; /* Adjust this value to ensure it's below the fixed navbar */
}

/* Optional: If you want to target specific sections individually */
.section {
  margin-top: 70px; /* Ensures sections start below the navbar */
}

/* Navbar adjustments */
.navbar {
  background-color: #024959;
  z-index: 1030; /* Ensures the navbar is above all content */
  position: fixed; /* Keeps the navbar fixed at the top */
  width: 100%;
}

/* Main container adjustment to avoid overlap with navbar */
.container {
  padding-top: 20px; /* Adds extra space within each container */
}

/* Additional General Styles */
body {
  padding-top: 56px; /* This is for general body padding to account for the navbar */
}

.navbar-brand, .nav-link {
  color: #ffffff !important;
}
.contact-section {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
footer {
  background-color: #ffc107 !important; /* Changed to Bootstrap warning color */
  color: #212529 !important; /* Changed text color to match better with warning background */
  padding: 20px 0 !important;
  text-align: center !important;
}

footer a {
  color: #212529; /* Ensure links are readable with the new background */
}

footer a:hover {
  text-decoration: none;
  color: #fff; /* Optional: Change to white on hover for better contrast */
}

/* footer a {
  color: #ffc107;
} */
footer a:hover {
  text-decoration: none;
  color: #fff;
}
.agent-img {
  width: 100%;  /* Sets the width to fill the container */
  height: 350px; /* Fixed height */
  object-fit: cover; /* Ensures the image covers the area without distorting aspect ratio */
  border-top-left-radius: .25rem; /* Optional: Matches Bootstrap's card image rounding */
  border-top-right-radius: .25rem; /* Optional: Matches Bootstrap's card image rounding */
}

/* contac */
body {
  font-family: 'Open Sans', sans-serif;
}
.contact-form {
  background: #f8f9fa;
  padding: 30px;
  border: 1px solid #dee2e6;
}
.contactImage {
  height: 50vh; /* 50% of the viewport height */
  /* background-image: url("/assets/images/contactpage.jpg"); */
  background-size: cover;
  background-position: center;
}
/* old finish */
/* About Us Page Styles */
.about-section {
  padding: 40px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-intro {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.testimonial-card {
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.testimonial-card:hover {
  transform: translateY(-5px);
}

.contact-info {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.contact-icon {
  font-size: 1.5rem;
  color: #024959;
}

.contact-link {
  color: #024959;
  text-decoration: none;
  font-weight: bold;
}

.contact-link:hover {
  color: #e0a800;
  text-decoration: underline;
}
